 .u-section-1 {
  background-image: none;
  min-height: 568px;
}

.u-section-1 .u-shape-1 {
  height: 230px;
  margin-top: 150px;
  margin-bottom: 0;
  background-image: none;
}

.u-section-1 .u-group-1 {
  min-height: 448px;
  width: 965px;
  margin: -320px auto -30px calc(((100% - 1140px) / 2) + 88px);
}

.u-section-1 .u-container-layout-1 {
  padding: 46px 46px 46px 55px;
}

.u-section-1 .u-text-1 {
  font-weight: 700;
  margin: 17px 570px 0 0;
}

.u-section-1 .u-btn-1 {
  border-style: none;
  font-size: 1.25rem;
  letter-spacing: 1px;
  font-weight: 400;
  margin: 20px auto 0 0;
  padding: 0;
}

.u-section-1 .u-btn-2 {
  border-style: none;
  font-size: 1.25rem;
  letter-spacing: 1px;
  margin: 20px auto 0 0;
  padding: 0;
}

.u-section-1 .u-text-2 {
  font-weight: 400;
  margin: 20px 570px 0 0;
}

.u-section-1 .u-social-icons-1 {
  white-space: nowrap;
  height: 32px;
  min-height: 16px;
  width: 176px;
  min-width: 112px;
  margin: 30px auto 0 0;
}

.u-section-1 .u-icon-4 {
  height: 100%;
}

.u-section-1 .u-icon-5 {
  height: 100%;
}

.u-section-1 .u-icon-6 {
  height: 100%;
}

.u-section-1 .u-icon-7 {
  height: 100%;
}

.u-section-1 .u-form-1 {
  height: 307px;
  margin: -270px 0 0 394px;
}

.u-section-1 .u-btn-3 {
  border-style: none;
  font-weight: 600;
  text-transform: uppercase;
}

@media (max-width: 1199px) {
  .u-section-1 .u-group-1 {
    width: 940px;
    margin-left: calc(((100% - 940px) / 2));
  }

  .u-section-1 .u-text-1 {
    margin-right: 545px;
  }

  .u-section-1 .u-text-2 {
    margin-right: 545px;
  }

  .u-section-1 .u-form-1 {
    margin-left: 369px;
  }

  .u-section-1 .u-btn-3 {
    width: 100%;
  }
}

@media (max-width: 991px) {
   .u-section-1 {
    min-height: 504px;
  }

  .u-section-1 .u-group-1 {
    min-height: 384px;
    width: 720px;
    margin-left: auto;
  }

  .u-section-1 .u-container-layout-1 {
    padding: 30px;
  }

  .u-section-1 .u-text-1 {
    margin-right: 366px;
  }

  .u-section-1 .u-text-2 {
    margin-right: 366px;
  }

  .u-section-1 .u-form-1 {
    margin-left: 190px;
  }
}

@media (max-width: 767px) {
   .u-section-1 {
    min-height: 833px;
  }

  .u-section-1 .u-group-1 {
    min-height: 713px;
    width: 540px;
  }

  .u-section-1 .u-text-1 {
    margin-right: 226px;
  }

  .u-section-1 .u-text-2 {
    margin-right: 226px;
  }

  .u-section-1 .u-form-1 {
    margin-left: 50px;
  }
}

@media (max-width: 575px) {
  .u-section-1 .u-group-1 {
    width: 340px;
  }

  .u-section-1 .u-container-layout-1 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .u-section-1 .u-text-1 {
    margin-right: 26px;
  }

  .u-section-1 .u-text-2 {
    margin-right: 26px;
  }

  .u-section-1 .u-form-1 {
    margin-left: 0;
  }
}