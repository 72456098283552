 .u-section-1 {
  background-image: none;
}

.u-section-1 .u-sheet-1 {
  min-height: 50vw;
}

.u-section-1 .u-text-1 {
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  margin: 41px 0 0;
}

.u-section-1 .u-layout-wrap-1 {
  margin-top: 0;
  margin-bottom: 0;
}

.u-section-1 .u-layout-cell-1 {
  min-height: 184px;
}

.u-section-1 .u-container-layout-1 {
  padding: 30px 30px 30px 0;
}

.u-section-1 .u-text-2 {
  margin-top: 0;
  font-size: 1.5rem;
  margin-bottom: 0;
}

.u-section-1 .u-layout-cell-2 {
  min-height: 184px;
}

.u-section-1 .u-container-layout-2 {
  padding: 30px;
}

.u-section-1 .u-text-3 {
  line-height: 2.2;
  margin: 0;
}

.u-section-1 .u-layout-cell-3 {
  min-height: 201px;
}

.u-section-1 .u-container-layout-3 {
  padding: 29px 0;
}

.u-section-1 .u-text-4 {
  line-height: 2.2;
  margin: 0 0 0 22px;
}

.u-section-1 .u-layout-wrap-2 {
  margin-top: -38px;
  margin-bottom: 6px;
}

.u-section-1 .u-layout-cell-4 {
  min-height: 674px;
  background-image: linear-gradient(white, #00adef);
}

.u-section-1 .u-container-layout-4 {
  padding: 30px 7px;
}

.u-section-1 .u-text-5 {
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 1;
  margin: 11px auto 0 0;
}

.u-section-1 .u-text-6 {
  font-size: 1.25rem;
  margin: 8px 46px 0 0;
}

.u-section-1 .u-text-7 {
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 3.75rem;
  line-height: 1;
  margin: 40px auto 0 23px;
}

.u-section-1 .u-text-8 {
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0 19px 0 27px;
}

.u-section-1 .u-btn-1 {
  font-size: 1rem;
  letter-spacing: 1px;
  border-style: none;
  background-image: none;
  font-weight: 700;
  margin: 42px auto 0;
  padding: 12px 48px 12px 46px;
}

.u-section-1 .u-text-9 {
  font-size: 1.125rem;
  font-weight: 700;
  margin: 18px 23px 0;
}

.u-section-1 .u-text-10 {
  font-size: 0.875rem;
  margin: 4px 23px 0;
}

.u-section-1 .u-layout-cell-5 {
  min-height: 674px;
  background-image: linear-gradient(white, #00adef);
}

.u-section-1 .u-container-layout-5 {
  padding: 30px 0;
}

.u-section-1 .u-text-11 {
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 1;
  margin: 11px auto 0 4px;
}

.u-section-1 .u-text-12 {
  font-size: 1.25rem;
  margin: 0 35px 0 4px;
}

.u-section-1 .u-text-13 {
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 3.75rem;
  line-height: 1;
  margin: 54px auto 0 31px;
}

.u-section-1 .u-text-14 {
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0 29px 0 31px;
}

.u-section-1 .u-btn-2 {
  font-size: 1rem;
  letter-spacing: 1px;
  border-style: none;
  background-image: none;
  font-weight: 700;
  margin: 36px auto 0 56px;
  padding: 12px 48px 12px 46px;
}

.u-section-1 .u-text-15 {
  font-size: 1.125rem;
  font-weight: 700;
  margin: 18px 60px 0 0;
}

.u-section-1 .u-text-16 {
  font-size: 0.875rem;
  margin: 4px 60px 0 0;
}

.u-section-1 .u-layout-cell-6 {
  min-height: 674px;
  background-image: linear-gradient(white, #77aad9);
}

.u-section-1 .u-container-layout-6 {
  padding: 30px 0;
}

.u-section-1 .u-text-17 {
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 1;
  margin: 8px auto 0 4px;
}

.u-section-1 .u-btn-3 {
  border-style: none;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 1px;
  background-image: none;
  margin: -32px auto 0 90px;
  padding: 4px 25px 5px 23px;
}

.u-section-1 .u-text-18 {
  font-size: 1.25rem;
  margin: 4px 40px 0 4px;
}

.u-section-1 .u-text-19 {
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 1;
  margin: 75px auto 0 25px;
}

.u-section-1 .u-text-20 {
  font-size: 1rem;
  font-weight: 700;
  margin: 0 35px 0 25px;
}

.u-section-1 .u-btn-4 {
  font-size: 1rem;
  letter-spacing: 1px;
  border-style: none;
  background-image: none;
  font-weight: 700;
  margin: 49px auto 0 60px;
  padding: 12px 48px 12px 46px;
}

@media (max-width: 1199px) {
  .u-section-1 .u-layout-cell-1 {
    min-height: 152px;
  }

  .u-section-1 .u-layout-cell-2 {
    min-height: 152px;
  }

  .u-section-1 .u-layout-cell-3 {
    min-height: 166px;
  }

  .u-section-1 .u-text-4 {
    margin-left: 0;
  }

  .u-section-1 .u-layout-cell-4 {
    min-height: 556px;
  }

  .u-section-1 .u-container-layout-4 {
    padding-left: 29px;
    padding-right: 29px;
  }

  .u-section-1 .u-text-6 {
    margin-right: 0;
  }

  .u-section-1 .u-text-8 {
    margin-right: 0;
    margin-left: 0;
  }

  .u-section-1 .u-text-9 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-1 .u-text-10 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-1 .u-layout-cell-5 {
    min-height: 556px;
  }

  .u-section-1 .u-container-layout-5 {
    padding-left: 27px;
    padding-right: 27px;
  }

  .u-section-1 .u-text-12 {
    margin-right: 0;
  }

  .u-section-1 .u-text-14 {
    margin-right: 0;
    margin-left: 0;
  }

  .u-section-1 .u-btn-2 {
    margin-left: 0;
  }

  .u-section-1 .u-text-15 {
    margin-right: 0;
  }

  .u-section-1 .u-text-16 {
    margin-right: 0;
  }

  .u-section-1 .u-layout-cell-6 {
    min-height: 556px;
  }

  .u-section-1 .u-container-layout-6 {
    padding-left: 27px;
    padding-right: 27px;
  }

  .u-section-1 .u-btn-3 {
    margin-left: 23px;
  }

  .u-section-1 .u-text-18 {
    margin-right: 0;
    margin-left: 0;
  }

  .u-section-1 .u-text-20 {
    margin-right: 0;
  }

  .u-section-1 .u-btn-4 {
    margin-left: 0;
  }
}

@media (max-width: 991px) {
  .u-section-1 .u-text-1 {
    font-size: 2.75rem;
  }

  .u-section-1 .u-layout-cell-1 {
    min-height: 100px;
  }

  .u-section-1 .u-text-2 {
    font-size: 1.25rem;
  }

  .u-section-1 .u-layout-cell-2 {
    min-height: 100px;
  }

  .u-section-1 .u-container-layout-2 {
    padding-right: 20px;
    padding-left: 0;
  }

  .u-section-1 .u-layout-cell-3 {
    min-height: 100px;
  }

  .u-section-1 .u-container-layout-3 {
    padding-right: 20px;
  }

  .u-section-1 .u-layout-cell-4 {
    min-height: 100px;
  }

  .u-section-1 .u-container-layout-4 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .u-section-1 .u-layout-cell-5 {
    min-height: 100px;
  }

  .u-section-1 .u-container-layout-5 {
    padding-left: 19px;
    padding-right: 19px;
  }

  .u-section-1 .u-layout-cell-6 {
    min-height: 100px;
  }

  .u-section-1 .u-container-layout-6 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .u-section-1 .u-btn-3 {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .u-section-1 .u-text-1 {
    font-size: 3.4375rem;
  }

  .u-section-1 .u-container-layout-1 {
    padding-right: 10px;
  }

  .u-section-1 .u-container-layout-2 {
    padding-right: 10px;
  }

  .u-section-1 .u-container-layout-3 {
    padding-top: 0;
    padding-right: 0;
  }

  .u-section-1 .u-container-layout-4 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .u-section-1 .u-container-layout-5 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .u-section-1 .u-container-layout-6 {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 575px) {
  .u-section-1 .u-text-1 {
    font-size: 2.75rem;
  }

  .u-section-1 .u-container-layout-2 {
    padding-top: 0;
  }

  .u-section-1 .u-text-5 {
    font-size: 3.75rem;
  }

  .u-section-1 .u-text-7 {
    font-size: 1.875rem;
  }

  .u-section-1 .u-text-11 {
    font-size: 1.875rem;
  }

  .u-section-1 .u-text-13 {
    font-size: 1.875rem;
  }

  .u-section-1 .u-text-17 {
    font-size: 1.875rem;
  }

  .u-section-1 .u-text-19 {
    font-size: 3.75rem;
  }
}